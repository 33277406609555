import React, { useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link }from "../links";
import classNames from "classnames";
import "./cta.scss";
gsap.registerPlugin(ScrollTrigger);

const CTA = ({
  children,
  to,
  isOutbound = false,
  className,
  submitted = false,
  disable = false,
  theme,
  button = false,
  isAnchor = false,
  text,
  onClick = () => {},
}) => {
  const ctaClasses = classNames("ri-cta", {
    "ri-cta--hollow": theme === "hollow",
    "ri-cta--secondary": theme === "secondary",
    "ri-cta--hollow-black": theme === "hollow-black",
    "ri-cta--black": theme === "black",
    "ri-cta--green": theme === "green",
    "ri-cta--large": theme === "large",
    "ri-cta--large-blue": theme === "large-blue",
    "ri-cta--hollow-large": theme === "hollow-large",
    "ri-cta--hollow-large-black": theme === "hollow-large-black",
    "ri-cta--disabled": button && disable,
  });

  const buttonCTA = button ? (
    <button
      type="submit"
      to={to}
      isOutbound={isOutbound}
      className={`${ctaClasses} ${className}`}
      onClick={onClick}
    >
      <p className={`${text}`}>{children}</p>
      <span className="ri-cta__transition"></span>
      <div className="ri-cta__arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--white)"
            d="M4 11v2h12l-5.5 5.5l1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5L16 11z"
          />
          <path
            fill="var(--white)"
            d="M4 11v2h12l-5.5 5.5l1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5L16 11z"
          />
        </svg>
      </div>
    </button>
  ) : (
    <Link
      to={to}
      isOutbound={isOutbound}
      className={`${ctaClasses} ${className}`}
      onClick={ScrollTrigger.refresh()}
    >
      <p className={`${text}`}>{children}</p>
      <span className="ri-cta__transition"></span>
      <div className="ri-cta__arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--white)"
            d="M4 11v2h12l-5.5 5.5l1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5L16 11z"
          />
          <path
            fill="var(--white)"
            d="M4 11v2h12l-5.5 5.5l1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5L16 11z"
          />
        </svg>
      </div>
    </Link>
  );

  return (
    <>
    {buttonCTA}
    </>
  );
};

export default CTA;
