import "./src/styles/global.scss";
import "./src/styles/global.css";
import packageInfo from "./package.json";

if (typeof window !== "undefined") {
  window.rothsteinInsurance = window.rothsteinInsurance || {};
  window.rothsteinInsurance.version = packageInfo.version;
}

export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  const { location } = routerProps;


  // If there's a hash in the URL, don't scroll, let the browser handle it
  if (location.hash) {
    return false;
  }

  // Handle the page navigation action
  if (routerProps.location.action === "PUSH") {
    window.scrollTo(0, 0);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    if (savedPosition) {
      window.scrollTo(...savedPosition);
    }
  }

  return false; // Prevent Gatsby's default scroll handling
};

