import React, {
  useRef,
  useState,
  useContext,
  forwardRef,
  useEffect,
} from "react";
import { Link } from "../../links";
import { insurance, business } from "../../../data/pages/homepage";
import classNames from "classnames";
import { navLinks } from "../../../data/components/header";
import { useLocation } from "@reach/router";
import ReactContext from "../../../context/reactContext";
import { assetArrayToObject } from "../../../lib/graphql-helpers";
import "./header.scss";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import Button from "../../Button";

const Header = forwardRef(({ data, pathname = "/" }, ref) => {
  const {
    insurance: { edges: insuranceImages },
    business: { edges: businessImages },
  } = data;

  const sortedInsuranceImages = assetArrayToObject({
    useGatsbyImage: true,
    images: insuranceImages,
  });

  const sortedBusinessImages = assetArrayToObject({
    useGatsbyImage: true,
    images: businessImages,
  });

  const [linksMenuOpen, setLinksMenuOpen] = useState(false);
  const [currentServiceMenu, setCurrentServiceMenu] = useState(null);
  const { menuActive, setMenuActive, serviceMenuOpen, setServiceMenuOpen } =
    useContext(ReactContext);

  const linksRef = useRef(null);

  const rootPath =
    pathname !== "/" ? pathname.split("#")[0].replace(/\/$/, "") : "/";

  const innerClasses = classNames("ri-header__inner", {
    "ri-header__inner--service-open": serviceMenuOpen,
  });

  const linksClasses = classNames("ri-header__links", {
    "ri-header__links--open": linksMenuOpen,
  });
  const linksIndexClasses = classNames("ri-header__links__index", {
    "ri-header__links__index--open": linksMenuOpen,
  });

  const mobileNavClasses = classNames("ri-header__mobile-nav", {
    "ri-header__mobile-nav--active": menuActive,
  });

  const checkWhichHoverLink = (linkTitle) => {
    switch (linkTitle) {
      case "Industries":
        setServiceMenuOpen(true);
        break;
      case "Line of Business":
        setServiceMenuOpen(true);
        break;
      default:
        setServiceMenuOpen(false);
    }
  };

  useEffect(() => {}, [serviceMenuOpen, currentServiceMenu]);

  return (
    <header ref={ref} className="ri-header">
      <div
        className={`${innerClasses} ${
          serviceMenuOpen ? "border-b border-grey" : ""
        }`}
      >
        {!menuActive && (
          <Link className="ri-header__logo relative h-[60px]" to="/">
            {pathname === "/" && (
              <div
                className={`transition-all duration-100 ease-in-out ${
                  serviceMenuOpen ? "opacity-0" : "opacity-1"
                } absolute inset-0`}
              >
                <StaticImage
                  src="../../../images/light-logo-2.png"
                  alt="ri Logo"
                />
              </div>
            )}
            <div
              className={`transition-all duration-100 ease-in-out ${
                serviceMenuOpen && pathname === "/" ? "opacity-1" : "opacity-0"
              } absolute inset-0`}
            >
              <StaticImage
                src="../../../images/dark-logo-2.png"
                alt="ri Logo"
              />
            </div>
            <div
              className={`transition-all duration-100 ease-in-out ${
                pathname !== "/" ? "flex" : "hidden"
              } absolute inset-0`}
            >
              <StaticImage
                src="../../../images/rinsure-logo.png"
                alt="ri Logo"
              />
            </div>
          </Link>
        )}
        <div ref={linksRef} className={linksClasses}>
          <div className="ri-header__links__inner">
            {linksMenuOpen && (
              <div className="ri-header__links__menu-logo">
                <StaticImage
                  src="../../images/riWhite.png"
                  alt="ri Logo White"
                />
              </div>
            )}
            <div className={linksIndexClasses}>
              {navLinks.map((item) => {
                const { title, url } = item;
                const navLinkClasses = classNames("ri-header__nav-link", {
                  "ri-header__nav-link--active": rootPath === url,
                  "ri-header__nav-link--active--black":
                    rootPath === url && pathname !== "/",
                  "ri-header__nav-link--black": pathname !== "/",
                });
                return (
                  <div
                    onMouseOver={() => checkWhichHoverLink(title)}
                    onMouseEnter={() => setCurrentServiceMenu(title)}
                    key={title}
                    onClick={() => {
                      if (menuActive) {
                        setMenuActive(false);
                      }
                    }}
                    className={`${navLinkClasses} ${
                      serviceMenuOpen || pathname !== "/"
                        ? "text-black"
                        : "text-white"
                    }`}
                  >
                    <Link to={url}>{title}</Link>
                  </div>
                );
              })}
            </div>
            <div className={mobileNavClasses}>
              <h5>Navigation</h5>
              {navLinks.map((item) => {
                const { title, url } = item;
                const navLinkClasses = classNames(
                  "ri-header__mobile-nav__link",
                  {
                    "ri-header__mobile-nav__link--active": rootPath === url,
                  }
                );
                return (
                  <div
                    key={title}
                    onClick={() => {
                      if (menuActive) {
                        setMenuActive(false);
                      }
                    }}
                    className={navLinkClasses}
                  >
                    <Link to={url}>{title}</Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <Button
            isOutbound={true}
            theme={`${
              serviceMenuOpen || pathname !== "/" ? "hollow-black" : "hollow"
            }`}
            to="https://portal2018.nexsure.com/core#/login"
          >
            Client login
          </Button>
        </div>
      </div>
      <div
        onMouseOut={() => setServiceMenuOpen(false)}
        onMouseOver={() => setServiceMenuOpen(true)}
        className={`w-full flex flex-row items-start justify-start bg-white absolute top-0 transform custom-cubic-circ duration-450 z-10 rounded-b-xl box-border overflow-hidden ${
          serviceMenuOpen && currentServiceMenu === "Industries"
            ? "2xl:h-[40vh] h-[50vh]"
            : ""
        } ${
          serviceMenuOpen && currentServiceMenu === "Line of Business"
            ? "2xl:h-[70vh] h-[80vh]"
            : ""
        } ${serviceMenuOpen ? "2xl:h-[40vh] h-[50vh]" : "h-0"}`}
      >
        <div
          className={`w-[200vw] h-full flex flex-row pt-[100px] transform custom-cubic-circ duration-450 ${
            currentServiceMenu === "Industries"
              ? "translate-x-0"
              : "-translate-x-2/4"
          }`}
        >
          <div className="w-[100vw] items-center justify-center flex flex-col h-full">
            <div
              className={`w-full max-w-screen-2xl grid grid-cols-5 overflow-hidden box-border px-8 gap-4`}
            >
              {insurance.map((item) => {
                const { header, image, link } = item;
                return (
                  <div key={`item-${image}`}>
                    <Link
                      to={link}
                      className={`${
                        serviceMenuOpen ? "h-full opacity-1" : "h-0 opacity-0"
                      } transition-all duration-300 ease-in-out gap-3 flex flex-col`}
                    >
                      <div className="max-w-[276px] rounded-lg overflow-hidden">
                        <GatsbyImage
                          className="hover:scale-105 transition-all duration-300 ease-in-out brightness-90 hover:brightness-100"
                          image={sortedInsuranceImages[image]}
                          alt={header}
                        />
                      </div>
                      <h4 className="text-xl font-semibold">{header}</h4>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-[100vw] items-center justify-center flex flex-col h-full">
            <div
              className={`w-full max-w-screen-2xl grid grid-cols-4 overflow-hidden box-border px-8 gap-4`}
            >
              {business.map((item) => {
                const { header, image, link } = item;
                return (
                  <div >
                    <Link
                      to={link}
                      className={`${
                        serviceMenuOpen ? "h-full opacity-1" : "h-0 opacity-0"
                      } transition-all duration-300 ease-in-out gap-3 flex flex-col`}
                    >
                      <div className="max-w-[276px] rounded-lg overflow-hidden">
                        <GatsbyImage
                          className="hover:scale-105 transition-all duration-300 ease-in-out brightness-90 hover:brightness-100"
                          image={sortedBusinessImages[image]}
                          alt={header}
                        />
                      </div>
                      <h4 className="text-xl font-semibold">{header}</h4>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
});

export default Header;
