import React from "react";
import PropTypes from "prop-types";
import MainLayout from "./main-layout";
import "@fontsource-variable/inter";

const Layout = ({ children, pageContext }) => {
  switch (pageContext.layout) {
    default:
      return <MainLayout {...pageContext}>{children}</MainLayout>;
  }
};

Layout.propTypes = {
  children: PropTypes.any,
  pageContext: PropTypes.any,
};

export default Layout;
