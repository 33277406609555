export const insurance = [
  {
    header: "Marine Insurance",
    text: "You can count on Rothstein Insurance Services for specialized Marine Insurance product knowledge and experience in underwriting, risk management and claims. Our Insurance division has specialized Hull and P&I to meet the insurance needs of marine businesses.",
    image: "marine",
    index: 1,
    link: "/industries/marine-insurance",
  },
  {
    header: "Risk Management",
    text: "Our specialists will identify exposures that have potential for increased accidents and serious injuries. Resulting in lower insurance cost.",
    image: "risk",
    index: 2,
    link: "/industries/risk-management",
  },
  {
    header: "Technology Insurance",
    text: "Our insurance programs offer tech companies the coverages needed for experimentation and adaption in this ever-evolving industry.",
    image: "tech",
    index: 3,
    link: "/industries/technology-insurance",
  },
  {
    header: "Construction Insurance",
    text: "Our Construction Insurance consultants know your industry and all the legalities, we can create an ideal strategy to meet your needs. After sitting down with you or over the phone to assess your risks and goals, our construction insurance specialists will develop insurance solutions, then adjust as your business grows. We address risk with customised coverage for a wide range of construction projects and companies.",
    image: "construction",
    index: 4,
    link: "/industries/construction-insurance",
  },
  {
    header: "Business Owner",
    text: "Business Insurance Owner’s Policy (BOP) combines two very vital business insurance: business property and business liability.",
    image: "business",
    index: 5,
    link: "/industries/business-owner",
  },
];

export const business = [
  {
    header: "General Liability (GL)",
    image: "general",
    link: "/line-of-business/general-liability",
  },
  {
    header: "Home & Auto",
    image: "home",
    link: "/line-of-business/home-and-auto",
  },
  {
    header: "Workers Compensation",
    image: "workers",
    link: "/line-of-business/workers-compensation",
  },
  {
    header: "Building Owner",
    image: "building",
    link: "/line-of-business/building-owner-and-lesser-risk",
  },
  {
    header: "Bonds",
    image: "bonds",
    link: "/line-of-business/bonds",
  },
  {
    header: "Health Insurance",
    image: "health",
    link: "/line-of-business/health-insurance",
  },
  {
    header: "Marine Liability, Hull & P&I",
    image: "marine",
    link: "/line-of-business/marine-liability",
  },
];

export const partners = [
  {
    image: "progressive",
    animation: "ri-anim-left",
  },
  {
    image: "berkshire",
    animation: "ri-anim-bottom",
  },
  {
    image: "CNA",
    animation: "ri-anim-bottom",
  },
  {
    image: "hartford",
    animation: "ri-anim-right",
  },
]

