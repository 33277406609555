export const navLinks = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Industries",
    url: "/industries",
  },
  {
    title: "Line of Business",
    url: "/line-of-business",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Contact",
    url: "/contact",
  },
];
