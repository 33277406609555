// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-line-of-business-js": () => import("./../../../src/pages/line-of-business.js" /* webpackChunkName: "component---src-pages-line-of-business-js" */),
  "component---src-templates-industry-page-template-js": () => import("./../../../src/templates/industryPageTemplate.js" /* webpackChunkName: "component---src-templates-industry-page-template-js" */),
  "component---src-templates-line-of-business-template-js": () => import("./../../../src/templates/lineOfBusinessTemplate.js" /* webpackChunkName: "component---src-templates-line-of-business-template-js" */)
}

