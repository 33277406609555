import PropTypes from "prop-types";
import React, { useState, useContext, useEffect, useRef } from "react";
import { seoDataObj } from "../data/components/seo";
import withLocation from "../lib/withLocation";
import gsap from "gsap";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "../components/links";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Header, Footer } from "../components/globals";
import "./main-layout.scss";
import { SEO } from "../components/seo";
import ReactContext from "../context/reactContext";
import classNames from "classnames";
import Lenis from "lenis";
// import { GlobalCursor } from "../components/custom-cursor";
import useIsDesktop from "../lib/useIsDesktop";
import { StaticImage } from "gatsby-plugin-image";
import { useCheckScreenSize } from "../lib/checkScreenSize";
gsap.registerPlugin(ScrollTrigger);

const LandingPageLayout = ({
  children,
  location,
  title = "",
  description = "",
  meta = [],
  layout,
}) => {
  const data = useStaticQuery(graphql`
    query {
      insurance: allFile(
        filter: {
          extension: { eq: "jpg" }
          relativeDirectory: { eq: "insurance" }
        }
      ) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(width: 280, placeholder: BLURRED)
              fluid {
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
      business: allFile(
        filter: {
          extension: { eq: "jpg" }
          relativeDirectory: { eq: "business" }
        }
      ) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(width: 280, placeholder: BLURRED)
              fluid {
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `);

  const { pathname } = location;

  const [modalComponent, setModalComponent] = useState(null);
  const [menuActive, setMenuActive] = useState(false);
  const [serviceMenuOpen, setServiceMenuOpen] = useState(false);
  const [showFloatingButton, setShowFloatingButton] = useState(false);

  const headerRef = useRef(null);
  const floatingButtonRef = useRef(null);
  const scrollTriggersRef = useRef([]);

  const isTablet = useCheckScreenSize(1200);
  const isDesktop = useIsDesktop();

  const hamburgerClassses = classNames("floating-nav__button", {
    "floating-nav__button--active": menuActive,
  });
  const floatingNavClasses = classNames("floating-nav", {
    "floating-nav--active": showFloatingButton && !menuActive,
    "floating-nav--active--open": showFloatingButton && menuActive,
  });
  const floatingLogoClasses = classNames("ri-floating-logo", {
    "ri-floating-logo--active": showFloatingButton && !menuActive,
  });

  const overlayClasses = classNames("nav-overlay", {
    "nav-overlay--active": menuActive || serviceMenuOpen,
  });

  useEffect(() => {

    // Initialize Lenis scrolling instance
    const lenis = new Lenis({
      lerp: 0.1,
      duration: 1,
      wheelMultiplier: 1,
      touchMultiplier: 2,
      smoothWheel: true,
    });

    // Animation frame function to support Lenis
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    const rafId = requestAnimationFrame(raf);

    // Cleanup Lenis instance and animation frame on component unmount
    return () => {
      lenis.destroy();
      cancelAnimationFrame(rafId);
    };
  }, [menuActive]);

  useEffect(() => {
    // Cleanup ScrollTriggers from previous renders
    scrollTriggersRef.current.forEach((st) => st.kill());
    scrollTriggersRef.current = []; // Clear previous ScrollTriggers

    // Animation for handling tablet view
    if (isTablet && headerRef.current) {
      gsap.set(headerRef.current, {
        y: -headerRef.current.offsetHeight,
      });
      setShowFloatingButton(true);
      return; // Skip the rest of the effect for tablets
    }

    // Create ScrollTriggers if not on a tablet
    if (headerRef.current && !isTablet) {
      const onEnterTrigger = ScrollTrigger.create({
        start: "top+=150 top",
        end: "bottom bottom",
        trigger: headerRef.current,
        onEnter: () => {
          gsap.to(headerRef.current, {
            y: -headerRef.current.offsetHeight,
            duration: 0.1,
            delay: 0.25,
            ease: "power1.inOut",
            onComplete: () => setShowFloatingButton(true),
          });
        },
      });

      const onLeaveBackTrigger = ScrollTrigger.create({
        start: "top+=100 top",
        end: "bottom bottom",
        trigger: headerRef.current,
        onLeaveBack: () => {
          gsap.to(headerRef.current, {
            y: 0,
            duration: 0.25,
            ease: "power1.inOut",
            onComplete: () => {
              setShowFloatingButton(false);
              setMenuActive(false);
            },
          });
        },
      });

      // Store ScrollTrigger instances for cleanup
      scrollTriggersRef.current.push(onEnterTrigger, onLeaveBackTrigger);
    }

    // Cleanup ScrollTriggers on component unmount
    return () => {
      scrollTriggersRef.current.forEach((st) => st.kill());
      scrollTriggersRef.current = []; // Clear the reference array
    };
  }, [setShowFloatingButton, setMenuActive, isTablet, pathname]);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [pathname]);

  const seoData = seoDataObj[pathname] ||
    seoDataObj[pathname.substr(0, pathname.length - 1)] || {
      title,
      description,
      meta,
    };

  const safePathname = pathname || "/";

  return (
    <ReactContext.Provider
      value={{
        menuActive,
        setMenuActive,
        serviceMenuOpen,
        setServiceMenuOpen
      }}
    >
      <div className={`ri-site`}>
        {seoData && <SEO {...seoData} />}
        <Header data={data} ref={headerRef} pathname={safePathname} />
        <Link to="/" className={floatingLogoClasses}>
          <StaticImage src="../images/rinsure-logo.png" alt="rinsure logo" />
        </Link>
        <div
          ref={floatingButtonRef}
          className={`${floatingNavClasses} opacity-50`}
          onClick={() => setMenuActive(!menuActive)}
        >
          <button
            onClick={() => setMenuActive(!menuActive)}
            className={hamburgerClassses}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <div className="ri-main">{children}</div>
        <Footer location={location} />
      </div>
      {modalComponent}
      {/* <GlobalCursor pathname={pathname}/> */}
      <span className={overlayClasses}></span>
    </ReactContext.Provider>
  );
};

LandingPageLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withLocation(LandingPageLayout);
